import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'vie-scolaire',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/vie-scolaire/vie-scolaire.module').then( m => m.VieScolairePageModule)
  },
  {
    path: 'notes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notes/notes.module').then( m => m.NotesPageModule)
  },
  {
    path: 'caisse',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/caisse/caisse.module').then( m => m.CaissePageModule)
  },
  {
    path: 'caisse/:segment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/caisse/caisse.module').then( m => m.CaissePageModule)
  },
  {
    path: 'publish-cours',
    loadChildren: () => import('./pages/publish-cours/publish-cours.module').then( m => m.PublishCoursPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'cours',
    loadChildren: () => import('./pages/cours/cours.module').then( m => m.CoursPageModule)
  },
  {
    path: 'cours/:type',
    loadChildren: () => import('./pages/cours/cours.module').then( m => m.CoursPageModule)
  },
  {
    path: 'profil',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'bulletin',
    loadChildren: () => import('./pages/bulletin/bulletin.module').then( m => m.BulletinPageModule)
  },
  {
    path: 'ecolages',
    loadChildren: () => import('./pages/ecolages/ecolages.module').then( m => m.EcolagesPageModule)
  },
  {
    path: 'cours-details',
    loadChildren: () => import('./pages/cours-details/cours-details.module').then( m => m.CoursDetailsPageModule)
  },
  {
    path: 'reponse',
    loadChildren: () => import('./pages/reponse/reponse.module').then( m => m.ReponsePageModule)
  },
  {
    path: 'reponse/:type',
    loadChildren: () => import('./pages/reponse/reponse.module').then( m => m.ReponsePageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./pages/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'eleve-details',
    loadChildren: () => import('./pages/eleve-details/eleve-details.module').then( m => m.EleveDetailsPageModule)
  },  {
    path: 'stats',
    loadChildren: () => import('./pages/stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'list-eleves',
    loadChildren: () => import('./pages/list-eleves/list-eleves.module').then( m => m.ListElevesPageModule)
  },
  {
    path: 'enseignants',
    loadChildren: () => import('./pages/enseignants/enseignants.module').then( m => m.EnseignantsPageModule)
  },
  {
    path: 'classes',
    loadChildren: () => import('./pages/classes/classes.module').then( m => m.ClassesPageModule)
  },
  {
    path: 'classe-details',
    loadChildren: () => import('./pages/classe-details/classe-details.module').then( m => m.ClasseDetailsPageModule)
  },
  {
    path: 'enseignant',
    loadChildren: () => import('./pages/enseignant/enseignant.module').then( m => m.EnseignantPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'eleve-vie-scolaire',
    loadChildren: () => import('./pages/eleve-vie-scolaire/eleve-vie-scolaire.module').then( m => m.EleveVieScolairePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
